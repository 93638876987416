<template>
    <div class="vue-template  auth">
        <form @submit.prevent="forgetPassword">
            <h3>Forgot Password</h3>
            <div class="d-grid gap-3">
              <div class="form-group">
                  <label>Email</label>
                  <input type="email" class="form-control form-control-lg" v-model="user.email" />
              </div>

              <button type="submit" class="btn btn-dark btn-lg btn-block">Reset password</button>
            </div>
        </form>
    </div>
</template>

<script>
import { auth } from '@/services/firebase/index.js';

export default {
  data() {
    return {
      user: {   
        email: ''
      }
    };
  },
  methods: {
    forgetPassword() {
        auth.sendPasswordResetEmail(this.user.email)
        .then(() => {
            alert('Check your registered email to reset the password!')
            this.user = {   
              email: ''
            }
        }).catch((error) => {
          alert(error)
        })
    }
  }
};
</script>

<style>

</style>